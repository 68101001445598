/* eslint-disable camelcase */
import { makeAutoObservable } from 'mobx';

import { ToastDetails } from '../../components/common/Toast/Toast.tsx';
import registrationApClient from '../../apis/RegistrationApClient.tsx';

import { Errors, parseApiErrorResponse } from '../../utils/Errors.tsx';

import registrationDataLoader from './RegistrationDataLoader.tsx';

let timeOutId: NodeJS.Timeout | null = null;

class RegisterAppStore {
  toastDetails: Array<ToastDetails> | [] = [];
  name: string = '';
  nameInitials: string = '';
  avatarUrl: string = '';
  timezone: string = '';
  siteUrls: Array<string> = [];
  recaptchaSiteKey: string = '';
  serverError: Errors = '';
  isSessionExpired: boolean = false;
  isInSufficientPermission: boolean = false;
  isRecordInSufficientPermission: boolean = false;

  constructor() {
    makeAutoObservable(this);
    const { _appData } = registrationDataLoader;

    if (_appData) {
      const { admin_data, recaptcha_site_key } = _appData;
      if (admin_data) {
        const { name_initials, avatar_url, timezone, site_urls, name } = admin_data;
        this.name = name;
        this.nameInitials = name_initials;
        this.avatarUrl = avatar_url || '';
        this.timezone = timezone || '';
        this.siteUrls = site_urls || [];
      }

      this.recaptchaSiteKey = recaptcha_site_key;
    }
  }

  clearToast() {
    timeOutId = setTimeout(() => {
      this.toastDetails.shift();
      if (this.toastDetails.length) {
        this.clearToast();
      } else if (timeOutId) {
        clearTimeout(timeOutId);
        timeOutId = null;
      }
    }, 5000);
  }

  setToastDetails(details: ToastDetails) {
    this.toastDetails = [...this.toastDetails, details];
    if (!timeOutId) {
      this.clearToast();
    }
  }

  removeToast(toastId: string) {
    const index = this.toastDetails.findIndex((toast) => toast.id === toastId);
    if (index >= 0 && index < this.toastDetails.length) {
      this.toastDetails.splice(index, 1);
    }
  }

  setServerError(errors: Errors) {
    this.serverError = errors;
  }

  setSessionExpired(expired: boolean) {
    this.isSessionExpired = expired;
  }

  setInsufficientPermission(inSufficient: boolean) {
    this.isInSufficientPermission = inSufficient;
  }

  setRecordInsufficientPermission(inSufficient: boolean) {
    this.isRecordInSufficientPermission = inSufficient;
  }

  async signOut() {
    try {
      const response = await registrationApClient.delete('/webex_admin/sessions/logout');
      if (response.status === 200) {
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      this.setServerError(parseApiErrorResponse(error));
      return { success: false };
    }
  }
}

const registerAppStore = new RegisterAppStore();
export default registerAppStore;
