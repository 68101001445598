export type AdminData = {
  name_initials: string;
  avatar_url?: string;
  timezone?: string;
  site_urls: Array<string>;
  name: string;
};

export type AppData = {
  admin_data: AdminData;
  recaptcha_site_key: string;
};

class RegistrationDataLoader {
  _appData: AppData;

  constructor() {
    const rootElement = document.querySelector('#react-root');
    const rawAppData = rootElement ? rootElement.getAttribute('data-app-data') : '';
    this._appData = rawAppData ? JSON.parse(rawAppData) : {};
  }

  get captchaSiteKey(): string {
    return this._appData.recaptcha_site_key;
  }
}

const registrationDataLoader = new RegistrationDataLoader();
export default registrationDataLoader;
