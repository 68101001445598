import axios, { type Axios } from 'axios';

import appStore from '../stores/AppStore.tsx';

const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

const apiClient: Axios = axios.create({
  headers: {
    'X-CSRF-Token': token || '',
    Accept: 'application/json',
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.data?.need_spark_reauthorization === true) {
      // webex token expired
      appStore.setWebexTokenExpiredModal(true);
      appStore.setSparkAuthenticated(false);
    } else if (error.response?.data?.need_lms_reauthorization === true) {
      // lms reauth required
      appStore.setLMSAuthorized(false);
    } else if (error.response?.status === 401) {
      // app token expired which is 12 hrs
      appStore.setAppTokenExpired(true);
    }
    return Promise.reject(error);
  }
);

export default apiClient;
